import './bootstrap';
import TomSelect from "tom-select";

import {ready} from "./scripts/common.mjs";

import.meta.glob([
    '../images/**',
]);

function handleDeleteModal(elements = []){
    const deleteModal = document.getElementById('modal-confirm-delete');
    if (deleteModal) {
        deleteModal.addEventListener('show.bs.modal', function (event) {
            const deleteUrl = event.relatedTarget.getAttribute('data-delete-url');

            let message = event.relatedTarget.getAttribute('data-message');
            if (message == '' || message == 'undefined' || message == null) {
                message = deleteModal.querySelector('.modal-body-default').innerHTML;
            }

            const deleteForm = deleteModal.querySelector('.delete-form');
            const body = deleteModal.querySelector('.modal-body');

            deleteForm.action = deleteUrl;
            body.innerHTML = message;
        });
    }

    const confirmModal = document.getElementById('modal-confirm-link');
    if (confirmModal) {
        confirmModal.addEventListener('show.bs.modal', function (event) {
            const linkUrl = event.relatedTarget.getAttribute('data-link-url');

            let message = event.relatedTarget.getAttribute('data-message');
            if (message == '' || message == 'undefined' || message == null) {
                message = confirmModal.querySelector('.modal-body-default').innerHTML;
            }

            const link = confirmModal.querySelector('.modal-confirm-link');
            const body = confirmModal.querySelector('.modal-body');

            link.href = linkUrl;
            body.innerHTML = message;
        });
    }
}

function handleDataFormSubmit() {
    const dataFormSubmitId = document.querySelectorAll('[data-form-submit-id]')
    dataFormSubmitId.forEach(function (e) {
        e.addEventListener('click', function () {
            document.getElementById(this.dataset.formSubmitId).click()
        })
    })
}

function handleTomSelect() {
    let tomSelectElems = document.querySelectorAll('.tom-select');
    tomSelectElems.forEach((tomSelectElem) => {
        new TomSelect(tomSelectElem, {
            copyClassesToDropdown: false
        });
    });
}

function handlePageSearch() {
    const searchField = document.getElementById('page-search-field');
    const searchSubmit = document.getElementById('page-search-submit');

    if (searchField && searchSubmit) {
        searchSubmit.addEventListener('click', ev => {
            ev.preventDefault();
            window.find(searchField.value);
        })
    }
}

function handleGoTopBtn() {
    const btn = document.getElementById('go-to-the-top');

    if (btn) {
        window.onscroll = function() {scrollFunction()};
        btn.style.display = "none";

        function scrollFunction() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                btn.style.display = "block";
            } else {
                btn.style.display = "none";
            }
        }

        btn.addEventListener('click', function topFunction() {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        });
    }
}

ready(function(){
    handleDeleteModal();
    handleDataFormSubmit();
    handleTomSelect();
    handlePageSearch();
    handleGoTopBtn();
});

document.openConfirmModalJsfree = function (link, message, confirmBtText, cancelBtText, titleText, confirmModaJsfreeID) {
    let modalId = confirmModaJsfreeID;
    if(modalId == '' || modalId == 'undefined' || modalId == null){
        modalId = 'modal-confirm-jsfree--default';
    }
    const confirmModalJsfreeElem = document.getElementById(modalId);
    if (confirmModalJsfreeElem) {
        if (message == '' || message == 'undefined' || message == null) {
            message = confirmModalJsfreeElem.querySelector('.modal-body-default').innerHTML;
        }

        const modalLink = confirmModalJsfreeElem.querySelector('.modal-confirm-link');
        const modalBody = confirmModalJsfreeElem.querySelector('.modal-body');

        modalLink.href = link;
        modalBody.innerHTML = message;

        if (!(confirmBtText == '' || confirmBtText == 'undefined' || confirmBtText == null)) {
            confirmModalJsfreeElem.querySelector('.modal-confirm-bt').innerHTML = confirmBtText;
        }
        if (!(cancelBtText == '' || cancelBtText == 'undefined' || cancelBtText == null)) {
            confirmModalJsfreeElem.querySelector('.modal-cancel-bt').innerHTML = cancelBtText;
        }
        if (!(titleText == '' || titleText == 'undefined' || titleText == null)) {
            confirmModalJsfreeElem.querySelector('.modal-title').innerHTML = titleText;
        }

        bootstrap.Modal.getOrCreateInstance(confirmModalJsfreeElem).show();
    }
}
